<template>
  <div class="content_box theme_bgText statistics">
    <div class="condition">
      
    </div>
    <van-nav-bar
      class="nav-bar"
      title="详细数据"
      left-text="返回"
      right-text=""
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="table-box">
      <p class="titleColor">{{tiemtxt}}</p>
      <!-- <ul class="element">
        <li v-for="item of elementList" :class="{ 'el_active': elActive == item.name }" @click="selectElement(item)"
          :key="item.name">
            {{ item.name }}
            <span style="display:none;">{{item.unit}}</span>
          </li>
      </ul> -->
      <my-table :headData="table.tableHead" :bodyData="table.tableBody" :isTableLeft='false' @goSort="sortClick"
        ref="myTable">
        <template slot-scope="scope">
            <div> {{ addUnit(scope.row.key,scope.row.data) }}</div>
        </template>
      </my-table>
    </div>
  </div>
</template>

<script>
import myTimePicker from '@/components/myTimePicker'
import { Format } from '@/utils/common.js'
// import myTable from '@/components/myTable'
import myTable from '@/components/TQTable'
import { Get_DataInfo } from './Api.js'
export default {
  components: {
    myTimePicker,
    myTable
  },
  name: "statistics",
  props:{tableData:[], keyName:'',tiemtxt:''},
  data() {
    return {
      Areacode: JSON.parse(localStorage.getItem("userInfo")).Areacode,
      minDate: "",
      maxDate: '',
      queryData: {
        dateTime: "",
        stationlist: "国家站"
      },
      elementList: [
        {
          name: '1',
          unit:'°C',
          HeadType: { name: '气温(℃)', prop: 'data', isSort: true, SortType: "" }
        },
        {
          name: '2',
          unit:'°C',
          HeadType: { name: '气温(℃)', prop: 'data', isSort: true, SortType: "" }
        },
        {
          name: '3',
          unit:'°C',
          HeadType: { name: '气温(℃)', prop: 'data', isSort: true, SortType: "" }
        },
        {
          name: '5', 
          unit:'%',
          HeadType: {name: '相对湿度(%)', prop: 'data',isSort:true, SortType:"" } 
        },
        {
          name: '0', 
          unit:'mm',
          HeadType: { name: '水量(mm)', prop: 'data', isSort: true, SortType: "" }
        },
        {
          name: '8', 
          unit:'mm',
          HeadType: { name: '降雪(mm)', prop: 'data', isSort: true, SortType: "" }
        },
        {
          name: '4', 
          unit:'m/s',
          HeadType: {name: '极大风速(m/s)', prop: 'data',isSort:true, SortType:"" } 
        },
        {
          name: '6', 
          unit:'km',
          HeadType: {name: '能见度(km)', prop: 'data',isSort:true, SortType:"" }
        },
      ],
      table: {
        tableHead: [
          {
            name: '站名',
            prop: 'StationName3',
            isSlot:true,
          },
          {
            name: '所属区域',
            prop: 'Areanmae',
            isSlot:true,
          }
        ],
        CopyTableBody: [],
        tableBody: []
      }
    }
  },
  created() {
    var date = new Date();
    this.queryData.dateTime = Format(date, 'yyyy/MM/DD HH:00:00');
  },
  mounted() {
    console.log(this.keyName)
    this.getDataList();
  },
  methods: {
    // 返回
    onClickLeft(){
      this.$emit('goBack')
    },

    // 获取数据
    async getDataList() {
      const obj = Object.assign({}, this.queryData);
      obj.area = this.Areacode+'';
      // const res = await Get_DataInfo(obj);
      const res = this.tableData;
      var data = res;
      this.table.tableBody = data;
      this.table.CopyTableBody = this.objDeepCopy(data);
      // console.log(this.elementList,  this.keyName);
      this.table.tableHead.push(this.elementList.filter(it=> it.name == this.keyName )[0].HeadType);
      // console.log(this.table.tableHead);
    },

    // 排序
    sortClick(item) {
      const { SortType, prop } = item;
      if (SortType == 'Asc') {
        this.table.tableBody.sort((a, b) => a[prop] - b[prop]);
      } else if (SortType == 'Desc') {
        this.table.tableBody.sort((a, b) => b[prop] - a[prop]);
      } else {
        this.table.tableBody = this.objDeepCopy(this.table.CopyTableBody);
      }
    },

    // 对象数组深拷贝
    objDeepCopy: function (source) {
      var sourceCopy = source instanceof Array ? [] : {};
      for (var item in source) {
        console.log(item);
        sourceCopy[item] = typeof source[item] === 'object' ? this.objDeepCopy(source[item]) : source[item];
      }
      return sourceCopy;
    },

    // 增加单位
    addUnit(key,val){
      // console.log(key,val)
      var item = this.elementList.filter(it=> it.name == this.keyName )[0];
      // console.log(item)
      if(val == 999999) return '/'
      var text = val + ' ' + item.unit
      return text;
    }
  },

  filters: {

  }
}
</script>

<style lang="scss" scoped>
.titleColor{
  text-align: center;
  margin: 0;
  background: var(--metar_bg1);
}
.nav-bar{
  background-color: transparent;
  ::v-deep .van-nav-bar__title{
    color: #fff;
  }
  ::v-deep .van-icon,
  ::v-deep .van-nav-bar__text{
    color: #fff;
  }
}
.btns_query{
  font-size: 12px;
  
}
.statistics {
  padding: 10px 8px;

  .my_VanSearch {
    margin-top: 10px;
  }

  .table-box {
    // margin-top: 15px;
    max-width: 100%;


    .element {
      padding: 5px 5px 0px;
      background-color: #64C1A9;
      display: flex;

      .el_active {
        background-color: rgba($color: #000000, $alpha: 0.1);
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        span{
          display: inline-block !important;
        }
      }

      li {
        font-size: 15px;
        padding: 0 8px 5px;
        // width: ;
      }
    }
  }
}
</style>