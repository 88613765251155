
<template>
  <div>
    <van-search readonly class="my_VanSearch" @focus="showTimeFun" :right-icon="rightIcon" :left-icon="leftIcon"
      v-model="inputValue" :placeholder="placeholder"  v-bind="$attrs" />
    <van-popup v-model="showSelTime" position="bottom" :style="{ 'height': height }">
      <van-datetime-picker class="datetime-picker" v-model="currentTime" :min-date="minDate" :max-date="maxDate" :type="dateType" title="选择年月日小时" @confirm="confirm" />
      <!-- <div style="color:#333;">当前</div> -->
    </van-popup>
  </div>
</template>

<script>
import { Format } from "@/utils/common.js"
export default {
  props: {
    // 最小时间段
    minDate: {
      type:Date,
      default:()=>{
          var date = new Date()
          return new Date(date.getTime() - 24*60*60*1000 )
        }
    }, //前一天,
    // 最大时间段
    maxDate:{
      type:Date,
      default:()=>{
        var date = new Date()
        return new Date(date.getTime() + 24*60*60*1000)
      }
    },//后一天,
    // 选择时间类型
    dateType: {
      type: String,
      default: 'datehour'
    },
    // 时间转换格式
    format: {
      type: String,
      default: "yyyy/MM/DD HH:mm:ss"
    },
    // 绑定时间
    value: {
      required: true
    },
    // 弹出框高度
    height: {
      type: String,
      default: '40%'
    },
    // 站字符
    placeholder: {
      type: String,
      default: ''
    },
    // 左侧icon
    leftIcon: {
      type: String,
      default: ''
    },
    // 右侧icon
    rightIcon: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      showSelTime: false, // 弹窗显示
      inputValue: '', // 显示的时间
      currentTime: new Date(), // 选择的时间
    }
  },
  created() {
    this.DateFormat();
  },
  methods: {
    // 最大最小日期
    intervalDate(){
      let date = new Date();
      new Date(date.getTime() - 24*60*60*1000); //前一天
    },
    // 确定回调
    confirm(date) {
      console.log(Format(date))
      this.inputValue = Format(date, this.format);
      this.$emit('change', date);
      this.showSelTime = false;
    },
    // 格式转换
    DateFormat() {
      if (this.value == '') return;
      if(this.value) this.inputValue = Format(this.value, this.format);
     
    },
    showTimeFun() {
      this.showSelTime = true
    }
  }
}
</script>

<style lang="scss" scoped>
 ::v-deep .datetime-picker{
.van-picker__title{
      color: #576b95;
    }
  
}
</style>