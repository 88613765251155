
<template>
  <div>
    <div class="product-list">
      <!-- 可滑动的全表格 -->
      <div class="table-scroll-container" ref="tableScrollBox">
        <table class="table-scroll" border="0" cellpadding="0" cellspacing="0">
          <tbody class="tbody">
            <tr class="title-tr">
              <th class="ta-c" v-for="(item, index) in headData" :key="index" :class="{ 'first-col': index == 0 }">
                {{ item.name }}
                <span class="sort" v-if="item.isSort" @click="onSort(item)">
                  <!-- <van-icon name="arrow-down" /> -->
                  <van-icon class="up" name="play" :class="{ active: item.SortType == 'Asc' }"></van-icon>
                  <van-icon class="down" name="play" :class="{ active: item.SortType == 'Desc' }"></van-icon>
                </span>
              </th>
            </tr>
            <template>
              <tr class="tbody-content" v-for="(item, index) in bodyData" :key="index">
                <td class="info-box" v-for="(colItem, colIndex) in headData" :key="colItem.prop" v-if="colItem.isSlot"
                  :class="[{ 'first-col clearfix': colIndex == 0 }, colItem.class]">
                  {{ item[colItem.prop] }}
                </td>
                <td class="operation" v-else :key="colIndex" @click.stop.capture="doThis($event, item)">
                  <slot v-bind="$attrs" :row="{key:colItem.prop,data:item[colItem.prop]}"></slot>
                  <!-- {{colItem.prop}}{{item[colItem.prop]}} -->
                  <!-- <span>
                    {{item[headData[colIndex].scope].wendu}}
                  </span> -->
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <!-- 覆盖的左侧表格 -->
      <div class="table-left-container" v-if="isTableLeft">
        <table class="table-left" border="0" cellpadding="0" cellspacing="0"
          :class="{ 'norem-table-fixed-left': isLeft }">
          <tbody class="tbody">
            <tr class="title-tr">
              <th class="first-col">{{ headData[0].name }}</th>
            </tr>
            <template>
              <tr class="tbody-content" v-for="(item, index) in bodyData" :key="index">
                <td class="info-box" v-if="headData[0].prop">{{ item[headData[0].prop] }}</td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
    <!-- 动态箭头 -->
    <div class="arrow" v-if="bodyData.length != 0 && !arrow_left"></div>
  </div>
</template>
 
<script>
export default {
  props: {
    // 表头内容
    headData: {
      type: Array,
      default: () => []
    },
    // 表格内容
    bodyData: {
      type: Array,
      default: () => []
    },
    // 是否要固定左边列
    isTableLeft: {
      type: Boolean,
      default: () => true
    },
    tdSlot: {
      type: Boolean,
      default: () => false
    },
    // 是否要动态箭头
    arrow_left: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      isLeft: false,
    };
  },
  methods: {
    doThis(e, item) {
      // e.target.dataset.func是父组件操作下面元素的属性data-func
      this.$emit(e.target.dataset.func, item)
    },
    cleanSort(){
      for (var i = 0; i < this.headData.length; i++) {
          this.headData[i].SortType = ''
      }
      // 把该数据给父组件过去，并触发父组件排序事件
      this.$emit('goSort', {SortType:''})
    },
    // 排序
    onSort(item) {
      for (var i = 0; i < this.headData.length; i++) {
        if (this.headData[i].prop != item.prop) {
          this.headData[i].SortType = ''
        }
      }
      // 避免重复请求
      if (item.SortType == '') {
        item.SortType = 'Asc'
      } else if (item.SortType == 'Asc') {
        item.SortType = 'Desc'
      } else {
        item.SortType = ''
      }
      // 把该数据给父组件过去，并触发父组件排序事件
      this.$emit('goSort', item)
    }
  },
  mounted() {
    this.$refs.tableScrollBox.addEventListener(
      "scroll",
      () => {
        this.$refs.tableScrollBox.scrollLeft
        if (this.$refs.tableScrollBox.scrollLeft > 0) {
          this.isLeft = true;
        } else {
          this.isLeft = false;
        }
      },
      true
    );
  },
};
</script>

<style lang="scss" scoped>
.product-list {
  position: relative;
  // margin-top: 16px;
  overflow: hidden;
  // margin-bottom: 20px;

  .table-left {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    // background: #fff;
    border-collapse: collapse;
    text-align: left;

    .tbody-content {
      &:nth-child(2n+3) td {
        // background-color: #F8F8F8;
      }

      td {
        height: 40px;
        text-align: center;
        box-sizing: border-box;
      }
    }
  }

  .table-scroll-container {
    overflow-x: scroll;
    overflow-y: hidden;
    width: 100%;
  }

  .table-scroll {
    width: 100%;
    border-collapse: collapse;
    text-align: left;
    // background-color: #fff;

    .tbody-content:nth-child(2n+3) {
      // background-color: #F8F8F8;
    }
  }

  .title-tr {
    height: 17px;
    font-size: 12px;
    font-weight: 400;
    color: #333;
    // line-height: 17px;
    // background-color: #64C1A9;
    background-color: var(--theme_color);
    // border-bottom: 1px #E9E9E9 solid;

    .first-col {
      height: 32px;
      border-bottom: 0;
    }
  }

  .class-tr {
    background-color: #fff;
    width: 48px;
    height: 24px;
    font-size: 12px;
    line-height: 20px;
    box-sizing: border-box;
  }

  .first-col {
    // height: 40px;
    box-sizing: border-box;

    .first-col-cont {
      width: 100px;
    }
  }

  .info-box {
    height: 25px;
    text-align: center;
    box-sizing: border-box;
    font-size: 13px;
  }

  div::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  th,
  td {
    word-break: keep-all;
    // white-space: nowrap;
    padding: 0 5px;
    text-align: center;
    border: 1px solid rgba($color: #fff, $alpha: 0.2);
  }

  span {
    // color: #617DEF;
    
    padding: 0 8px;
    font-size: 12px;
  }

  th {
    ::v-deep i {
      color: #333;
      vertical-align: text-top;
    }

    .sort {
      padding: 0;
      position: relative;

      i {
        color: #333;
      }

      .up {
        position: absolute;
        top: -3px;
        transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        /* IE 9 */
        -moz-transform: rotate(-90deg);
        /* Firefox */
        -webkit-transform: rotate(-90deg);
        /* Safari 和 Chrome */
        -o-transform: rotate(-90deg);

        &.active {
          color: #0DF00D;
        }
      }

      .down {
        position: absolute;
        bottom: -2px;
        transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        /* IE 9 */
        -moz-transform: rotate(90deg);
        /* Firefox */
        -webkit-transform: rotate(90deg);
        /* Safari 和 Chrome */
        -o-transform: rotate(90deg);

        &.active {
          color: #0DF00D;
        }
      }
    }
  }

  .operation {
    min-width: 100px;
    ::v-deep span {
      color: #fff;
      padding: 0 8px;
      font-size: 12px;
    }
  }

  .ta-c {
    text-align: center;
  }

  .norem-table-fixed-left {
    box-shadow: 1px -3px 6px #00000029;
  }

  .add {
    font-size: 16px;
    vertical-align: text-top;
    color: #617DEF;
  }

  .arrow {
    display: block;
    width: 10px;
    height: 10px;
    position: fixed;
    top: 50%;
    right: 0;
    margin-left: -11px;
    border: 3px solid transparent;
    border-top: 3px solid #617DEF;
    border-left: 3px solid #617DEF;
    z-index: 99;
    opacity: .8;
    transform: rotate(313deg);
    animation: arrow 1.5s infinite ease-in-out;
  }

  @keyframes arrow {
    0% {
      opacity: 0.2;
      transform: translate(0, 0px) rotate(313deg)
    }

    50% {
      opacity: 1;
      transform: translate(-5px, 0) rotate(313deg)
    }

    100% {
      opacity: 0.2;
      transform: translate(-10px, 0) rotate(313deg)
    }
  }
}
</style>